<template>
    <div class="pd20x flex1">
        <div class="contentBox h100pct ofA">
            <div class="section">
                <a-comment>
                <span slot="actions" key="comment-nested-reply-to" @click="deleteQuestion(question.id)">删除该提问</span>
                <a slot="author">{{question.nickname}}</a>
                <a-avatar
                        slot="avatar"
                        :src="question.headimgurl"
                        :alt="question.nickname"
                />
                <p slot="content">
                    <span style="font-size: 25px">
                        {{question.title}}
                    </span>
                    <br>
                    <span>
                        {{question.content}}
                    </span>
                </p>
                <a-comment v-for="(item, index) in answerList" :key="index">
                    <span slot="actions" @click="deleteItem(item.id)">删除该回复</span>
                    <a slot="author">{{item.nickname}}</a>
                    <a-avatar
                            slot="avatar"
                            :src="item.headimgurl"
                            :alt="item.nickname"
                    />
                    <p slot="content">
                        {{item.content}}
                    </p>
                </a-comment>
            </a-comment>
            </div>
        </div>
    </div>
</template>
<script>
    import {formatDate} from '@/libs/moment'
    import * as http from '@/libs/practice'
    import {routerlink} from '@/mixins/routerlink'

    export default {
        name: 'answer',
        components: {},
        mixins: [routerlink],
        data() {
            return {
                id : '',
                answerList: [],
                question: {},
            }
        },
        mounted(){
            this.id = this.$route.query.id;
            this.$nextTick(()=>{
                Promise.all([]).then(res=>{
                    this.getData()
                })
            })
        },
        methods: {
            formatDate,
            deleteQuestion(id){
                let _this = this
                this.$confirm({
                    title: '是否确定删除该条提问信息吗?',
                    centered: true,
                    onOk() {
                        http.del_question({id}).then(res => {
                            _this.$router.push('/practice/question');
                        }).catch(error => {
                            console.log(error)
                        })
                    },
                });
            },
            getData() {
                let _this = this
                let request = {};
                request.id = this.id;
                http.get_answer_list(request).then(res => {
                    console.log(res)
                    _this.question = res.question;
                    _this.answerList = res.answerList
                }).catch(error => {
                    console.log(error)
                })
            },
            deleteItem(id) {
                let _this = this
                this.$confirm({
                    title: '是否确定删除该条留言信息吗?',
                    centered: true,
                    onOk() {
                        http.del_answer({id}).then(res => {
                            _this.getData()
                        }).catch(error => {
                            console.log(error)
                        })
                    },
                });
            },
        }
    }
</script>
<style lang="less" scoped>
    .section {
        border-bottom: 1px solid #ebedf0;
        padding: 0 24px;
        color: rgba(0,0,0,.65);
    }
    .imgWrap {
        width: 120px;
        height: 70px;
        overflow: hidden;
        border-radius: 4px;
        margin: auto;

        img {
            width: 100%;
        }
    }
</style>
